import {
  Badge,
  Table,
  Space,
  Input,
  Select,
  Button,
  Tooltip,
  message,
  Typography,
  Popconfirm,
  Pagination,
  InputNumber,
  Descriptions,
} from "antd";
import print from "print-js";
import { useStore } from "react-redux";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { PrinterOutlined, SyncOutlined } from "@ant-design/icons";

import "./Invoices.scss";
import { InvoiceData } from "./invoice.utils";
import httpClient from "../../common/httpClient";
import PaymentDialog from "../Payment/PaymentDialog";
import generateInvoice from "../../common/generateInvoice";

const { Option } = Select;
const { Link } = Typography;

export interface InvoicesProps {
  admin?: boolean;
}

const Invoices: React.FunctionComponent<InvoicesProps> = ({ admin }) => {
  const store = useStore();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(100);
  const [status, setStatus] = useState();
  const [init, setInit] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selRows, setSelRows] = useState<any[]>([]);
  const [invoiceId, setInvoiceId] = useState<number>();
  const [meta, setMeta] = useState({ paid: 0, unpaid: 0 });
  const [filter, setFilter] = useState<number | undefined>();
  const [invoices, setInvoices] = useState<InvoiceData[]>([]);
  const [username, setUsername] = useState<string | undefined>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const columns = [
    {
      key: "id",
      title: "Id",
      dataIndex: "id",
    },
    { title: "Credit", dataIndex: "credit", key: "credit" },
    {
      key: "tax",
      title: "Tax",
      dataIndex: "tax",
      render: (txt: string) => txt ?? "-",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a: any, b: any) => a.status.length - b.status.length,
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      render: (txt: string) =>
        new Date(txt).toLocaleString("en-US", { timeZone: "America/Virgin" }),
    },
    {
      title: "Method",
      key: "paymentMethod",
      dataIndex: "paymentMethod",
      render: (txt: string) => txt ?? "-",
    },
    {
      key: "credited",
      title: "Credited",
      dataIndex: "credited",
      render: (txt: boolean) => (txt ? "Yes" : "No"),
    },
    {
      key: "paidOn",
      title: "Paid On",
      dataIndex: "paidOn",
      render: (txt: string) => (txt ? new Date(txt).toUTCString() : "-"),
    },
    {
      key: "by",
      dataIndex: "by",
      title: "Invoice By",
    },
    // {
    //   key: "user",
    //   title: "User",
    //   dataIndex: "user",
    //   render: (r: any) => r.username,
    // },
    admin
      ? {
          key: "user",
          title: "User",
          dataIndex: "user",
          render: (r: any) => r.username,
        }
      : {},
    // {
    //   width: "80px",
    //   key: "action",
    //   dataIndex: "",
    //   title: "Action",
    //   render: (txt: string, record: InvoiceData, index: number) =>
    //     record.status === "Unpaid" ? (
    //       <Link onClick={() => handlePayInvoice(record.id)}>Pay Now</Link>
    //     ) : (
    //       "N\\A"
    //     ),
    // },
    {
      key: "print",
      dataIndex: "",
      title: "Actions",
      render: (txt: string, record: InvoiceData, index: number) => (
        <Space>
          <Link onClick={() => handlePrintInvoice([record])}>View</Link>
          <Link onClick={() => handlePayInvoice(record.id)}>Pay Now</Link>
          {admin && (
            <Popconfirm
              title="Are you sure you want to rebate ?"
              onConfirm={() => handleRebateInvoice(record.id)}
            >
              <Link>Rebate</Link>
            </Popconfirm>
          )}
          {admin && (
            <Popconfirm
              title="Are you sure you want to mark invoice as Paid ?"
              onConfirm={() => handleMarkInvoicePaidByAdmin(record.id)}
            >
              <Link type="danger" disabled={record.status === "Paid"}>
                Mark as Paid
              </Link>
            </Popconfirm>
          )}
        </Space>
      ),
    },
  ];

  useEffect(() => {
    if (init) setInit(false);
    fetchInvoices();
  }, [page, size]);

  /**
   * @param pageSize page size
   * @param pageNumber page number
   * @description pagination handler for batch listing
   */
  function handlePaginationChange(
    pageNumber: number,
    pageSize: number | undefined
  ) {
    setPage(pageNumber);
    if (pageSize) setSize(pageSize);
  }

  /**
   * @description fetch invoices from remote api
   */
  async function fetchInvoices() {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.get("/payment", {
        size,
        admin,
        filter,
        username,
        page: page - 1,
        status: status ? status : undefined,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      const _invoices: InvoiceData[] = data.invoices.map(
        (_invc: any, index: number) => {
          _invc["key"] = index;
          return _invc;
        }
      );
      const _meta = {
        paid: parseInt(data.paid),
        unpaid: parseInt(data.unpaid),
      };
      setMeta(_meta);
      setInvoices(_invoices);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description handle to pay invoice
   * @param id invoice id
   */
  function handlePayInvoice(id: number) {
    setInvoiceId(id);
    setShowDialog(true);
  }

  /**
   * @description print multiple records
   * @param records records array
   */
  async function handlePrintInvoice(records: any[]) {
    if (!!!records) {
      message.warning("No printable invoices selected.");
      return;
    }
    const pdfs = await generateInvoice(records);

    print({
      printable: pdfs,
    });
  }

  /**
   * @description rebate credit from client
   * @param id invoice id
   */
  async function handleRebateInvoice(id: number) {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.post("/payment/rebate", {
        id,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      await fetchInvoices();
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  /**
   * @description mark invoice paid by admin
   * @param id invoice id
   */
  async function handleMarkInvoicePaidByAdmin(id: number) {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.patch("/payment/admin/complete", {
        id,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      await fetchInvoices();
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  return (
    <>
      <Descriptions
        bordered
        size="small"
        column={{ xxl: 3, xl: 3, lg: 3, md: 3, sm: 2, xs: 1 }}
      >
        <Descriptions.Item label={<Badge text="Total" color="blue" />}>
          {meta.paid + meta.unpaid}
        </Descriptions.Item>
        <Descriptions.Item label={<Badge text="Paid" status="success" />}>
          {meta.paid}
        </Descriptions.Item>
        <Descriptions.Item label={<Badge text="Unpaid" status="warning" />}>
          {meta.unpaid}
        </Descriptions.Item>
      </Descriptions>

      <div className="order_filter_wrapper">
        <div style={{ width: "100%" }}>
          <Space style={{ width: "100%" }}>
            <InputNumber
              min={0}
              size="small"
              value={filter}
              placeholder="Search Invoice ID"
              onChange={(s) => setFilter(s as any)}
              disabled={!!!(meta.paid + meta.unpaid)}
              style={{ width: isMobile ? "100%" : "200px" }}
            />

            {!isMobile && admin && (
              <Input
                allowClear
                size="small"
                value={username}
                placeholder="username"
                onChange={(e) => setUsername(e.target.value as any)}
              />
            )}

            <Select
              allowClear
              size="small"
              value={status}
              placeholder="Status"
              onChange={setStatus as any}
              disabled={!!!(meta.paid + meta.unpaid)}
              style={{ width: isMobile ? "100%" : 120 }}
            >
              <Option value="Paid">Paid</Option>
              <Option value="Unpaid">Unpaid</Option>
            </Select>

            <Button
              size="small"
              type="primary"
              onClick={fetchInvoices}
              style={{ width: "100%" }}
            >
              Filter
            </Button>

            {isMobile && (
              <Tooltip title="Refresh">
                <Button
                  type="link"
                  loading={loading}
                  onClick={fetchInvoices}
                  icon={<SyncOutlined />}
                />
              </Tooltip>
            )}
          </Space>
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {!isMobile && (
            <div style={{ width: "8%" }}>
              <Tooltip title="Refresh">
                <Button
                  type="link"
                  loading={loading}
                  onClick={fetchInvoices}
                  icon={<SyncOutlined />}
                />
              </Tooltip>
            </div>
          )}

          <div
            style={
              isMobile ? { width: "100%", marginTop: "10px" } : { width: "90%" }
            }
          >
            <Button
              size="small"
              type="primary"
              style={{ width: "100%" }}
              icon={<PrinterOutlined />}
              disabled={!!!selRows.length}
              onClick={() => handlePrintInvoice(selRows)}
            >
              Print Selected
            </Button>
          </div>
        </div>
      </div>

      <Table
        sticky
        size="small"
        loading={loading}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        dataSource={invoices}
        className="invoice_table"
        rowSelection={{
          selectedRowKeys: selRows.map((row) => row.key),
          onChange: (keys, selRowss) => setSelRows(selRowss),
        }}
      />
      <div className="pagination">
        <Pagination
          size="small"
          current={page}
          pageSize={size}
          showSizeChanger
          total={meta.paid + meta.unpaid}
          onChange={handlePaginationChange}
          pageSizeOptions={["50", "100", "200", "500"]}
        />
      </div>
      <PaymentDialog
        show={showDialog}
        invoiceId={invoiceId}
        setShow={setShowDialog}
      />
    </>
  );
};

export default Invoices;

import moment from "moment";
import { useMediaQuery } from "react-responsive";
import React, { FunctionComponent, useState } from "react";
import { Input, Select, Space, message, Button, DatePicker, Badge } from "antd";

import "./Batch.scss";
import httpClient from "../../common/httpClient";

interface ExportDialogProps {
  admin?: boolean;
  close: () => void;
}

const { Option } = Select;
const { RangePicker } = DatePicker;

const ExportDialog: FunctionComponent<ExportDialogProps> = ({
  admin,
  close,
}) => {
  const [status, setBatchStatus] = useState();
  const [services, setService] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selService, setSelService] = useState<any>();
  const [imeis, setImeis] = useState<string | undefined>();
  const [subusers, setSubsuers] = useState<string | undefined>();
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  const [toDateM, setToDateM] = useState<moment.Moment | null>();
  const [fromDateM, setFromDateM] = useState<moment.Moment | null>();

  const [toDate, setToDate] = useState<string | null>();
  const [fromDate, setFromDate] = useState<string | null>();
  const [clearSearchInt, setClearSearchInt] = useState<NodeJS.Timeout>();

  function handleDateChange(
    date: [moment.Moment, moment.Moment],
    dateString: [string, string]
  ) {
    if (date) {
      setToDate(dateString[1]);
      setFromDate(dateString[0]);
      setToDateM(date[1]);
      setFromDateM(date[0]);
    } else {
      setToDate(null);
      setFromDate(null);
      setToDateM(null);
      setFromDateM(null);
    }
  }

  async function fetchServices(search?: string) {
    try {
      const { data } = await httpClient.get("/service", {
        page: 0,
        size: 10,
        filter: search,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setService(data.services);
    } catch (err) {
      message.error("Something went wrong.");
    }
  }

  function searchHandler(search: string) {
    if (clearSearchInt) clearTimeout(clearSearchInt);

    const timeOut = setTimeout(() => {
      fetchServices(search);
    }, 500);

    setClearSearchInt(timeOut);
  }

  async function exportOrder() {
    console.log(toDate ? new Date(toDate).toISOString() : null);
    console.log(fromDate ? new Date(fromDate).toISOString() : null);
    setLoading(true);
    try {
      const { data } = await httpClient.get("/order/export", {
        admin,
        orderStatus: status,
        serviceId: selService,
        to: toDate ? new Date(toDate).toISOString() : null,
        from: fromDate ? new Date(fromDate).toISOString() : null,
        imeis: imeis ? imeis?.split("\n").map((e) => e.trim()) : null,
        subusers: subusers ? subusers?.split("\n").map((e) => e.trim()) : null,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success({
        duration: 5,
        content: `Total ${data.total} orders exported.`,
      });

      window.open(
        `https://export.${process.env.REACT_APP_URL}/download?code=${data.code}`,
        "_blank"
      );

      close();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <Space direction="vertical" size={12} style={{ width: "100%" }}>
        <Input.TextArea
          size="small"
          value={subusers}
          className="imei_search_text_area"
          onChange={(e) => setSubsuers(e.target.value as any)}
          placeholder={`Search ${admin ? "users" : "sub-users"}`}
          style={{
            width: "100%",
            maxHeight: "100px",
          }}
        />

        <Input.TextArea
          size="small"
          value={imeis}
          placeholder="Search imeis"
          className="imei_search_text_area"
          onChange={(e) => setImeis(e.target.value as any)}
          style={{
            width: "100%",
            maxHeight: "100px",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              width: isMobile ? "100%" : "30%",
              marginBottom: isMobile ? "10px" : undefined,
            }}
          >
            <Select
              showSearch
              allowClear
              size="small"
              showArrow={false}
              value={selService}
              filterOption={false}
              onSearch={searchHandler}
              style={{ width: "100%" }}
              onChange={setSelService as any}
              notFoundContent="No Service Found."
              placeholder="Type to search service"
            >
              {services.map((each: any) => (
                <Select.Option value={each.id}>
                  {each.title}{" "}
                  {each.featured && (
                    <Badge color="gold" text="Featured" status="processing" />
                  )}
                </Select.Option>
              ))}
            </Select>
          </div>

          <div
            style={{
              width: isMobile ? "100%" : "50%",
              marginBottom: isMobile ? "10px" : undefined,
            }}
          >
            <RangePicker
              allowClear
              size="small"
              style={{ width: "100%" }}
              allowEmpty={[true, true]}
              onChange={handleDateChange as any}
              value={[fromDateM ?? null, toDateM ?? null]}
            />
          </div>

          <div style={{ width: isMobile ? "100%" : "18%" }}>
            <Select
              allowClear
              size="small"
              value={status}
              placeholder="Status"
              style={{ width: "100%" }}
              onChange={setBatchStatus as any}
            >
              <Option value="Failed">Failed</Option>
              <Option value="Waiting">Waiting</Option>
              <Option value="Success">Success</Option>
              <Option value="Processing">Processing</Option>
            </Select>
          </div>
        </div>

        <Button
          size="small"
          type="primary"
          loading={loading}
          onClick={exportOrder}
          style={{ width: "100%" }}
        >
          Download
        </Button>
      </Space>
    </div>
  );
};

export default ExportDialog;

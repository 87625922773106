import {
  Link,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import {
  BarsOutlined,
  FormOutlined,
  GroupOutlined,
  GlobalOutlined,
  SettingOutlined,
  ControlOutlined,
  UngroupOutlined,
  PieChartOutlined,
  FileTextOutlined,
  UserSwitchOutlined,
  ClockCircleOutlined,
  UsergroupAddOutlined,
  UnorderedListOutlined,
  FunctionOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { Layout, Menu, Modal } from "antd";
import React, { useEffect, useState } from "react";

import "./Base.scss";
import BaseHeader from "./BaseHeader";
import Batch from "../../Components/Batch/Batch";
import Preferences from "../Preferences/Preferences";
import History from "../../Components/History/History";
import SubUsers from "../../Components/SubUsers/SubUsers";
import Invoices from "../../Components/Invoices/Invoices";
import Analytics from "../../Components/Analytics/Analytics";
import Statements from "../../Components/Statement/Statement";
import BatchHisotry from "../../Components/Batch/BatchHistory";
import { UserDetails } from "../../actions/userdetails.action";
import AdminUsers from "../../Components/Administrator/Users/Users";
import AdminHistory from "../../Components/Administrator/History/History";
import LoginLogs from "../../Components/Administrator/LoginLogs/LoginLogs";
import AdminBatchHistory from "../../Components/Administrator/History/Batch";
import AdminSettings from "../../Components/Administrator/Settings/Settings";
import AdminProviders from "../../Components/Administrator/Providers/Providers";
import AdminServices from "../../Components/Administrator/Services/AdminServices";
// import ManualBatch from "../../Components/Administrator/ManualOrders/ManualOrders";

// const {Text} = Typography;
const { Header, Content, Sider } = Layout;

export interface BaseProps {
  userDetails: UserDetails;
}

const Base: React.FunctionComponent<BaseProps> = ({ userDetails }) => {
  const location = useLocation();
  const { path, url } = useRouteMatch();
  const [showAnalytics, setShowAnalytics] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const allowedHistory =
    userDetails.role === "Sub" ? userDetails.rights.history : true;

  const [activeMenu, setActiveMenu] = useState("/order");

  useEffect(() => {
    setActiveMenu(location.pathname);
  }, [location]);

  return (
    <>
      <Layout>
        <Header style={{ padding: "0 16px" }}>
          <BaseHeader />
        </Header>
        <Layout className="layout">
          <Sider
            collapsible
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={collapsed}
            onCollapse={(coll) => setCollapsed(coll)}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                overflowY: "auto",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <Menu
                  mode="inline"
                  className="side_menu_custom"
                  selectedKeys={[activeMenu]}
                  defaultSelectedKeys={["/"]}
                >
                  <Menu.Item key="/" icon={<ClockCircleOutlined />}>
                    <Link to={`${url}`}>Orders</Link>
                  </Menu.Item>
                  <Menu.Item key="/batchs" icon={<GroupOutlined />}>
                    <Link to={`${url}batchs`}>Batches</Link>
                  </Menu.Item>
                  {allowedHistory && (
                    <Menu.Item key="/history" icon={<UngroupOutlined />}>
                      <Link to={`${url}history`}>History</Link>
                    </Menu.Item>
                  )}
                  {userDetails.role !== "Sub" && (
                    <Menu.Item key="/invoices" icon={<FileTextOutlined />}>
                      <Link to={`${url}invoices`}>Invoices</Link>
                    </Menu.Item>
                  )}
                  {userDetails.role !== "Sub" && (
                    <Menu.Item key="/subusers" icon={<UsergroupAddOutlined />}>
                      <Link to={`${url}subusers`}>Sub Users</Link>
                    </Menu.Item>
                  )}
                  <Menu.Item key="/statements" icon={<UnorderedListOutlined />}>
                    <Link to={`${url}statements`}>Statements</Link>
                  </Menu.Item>
                  <Menu.Item key="/preferences" icon={<SettingOutlined />}>
                    <Link to={`${url}preferences`}>Preferences</Link>
                  </Menu.Item>
                  {userDetails.role === "Admin" && (
                    <Menu.SubMenu
                      key="admin"
                      title="Administrator"
                      icon={<ControlOutlined />}
                    >
                      <Menu.Item
                        key="/admin/users"
                        icon={<UserSwitchOutlined />}
                      >
                        <Link to={`${url}admin/users`}>Users</Link>
                      </Menu.Item>
                      <Menu.Item key="/admin/services" icon={<BarsOutlined />}>
                        <Link to={`${url}admin/services`}>Services</Link>
                      </Menu.Item>
                      <Menu.Item
                        key="/admin/providers"
                        icon={<FunctionOutlined />}
                      >
                        <Link to={`${url}admin/providers`}>Providers</Link>
                      </Menu.Item>

                      <Menu.Item
                        key="/admin/login-logs"
                        icon={<UnorderedListOutlined />}
                      >
                        <Link to={`${url}admin/login-logs`}>Login Logs</Link>
                      </Menu.Item>

                      <Menu.Item
                        key="/admin/batch-history"
                        icon={<GroupOutlined />}
                      >
                        <Link to={`${url}admin/batch-history`}>
                          Batch History
                        </Link>
                      </Menu.Item>
                      <Menu.Item
                        key="/admin/history"
                        icon={<UngroupOutlined />}
                      >
                        <Link to={`${url}admin/history`}>Orders History</Link>
                      </Menu.Item>

                      {/* <Menu.Item
                        key="/admin/manual-orders"
                        icon={<FormOutlined />}
                      >
                        <Link to={`${url}admin/manual-orders`}>
                          Manual Orders
                        </Link>
                      </Menu.Item> */}
                      <Menu.Item
                        key="/admin/invoices"
                        icon={<FileTextOutlined />}
                      >
                        <Link to={`${url}admin/invoices`}>Invoice history</Link>
                      </Menu.Item>
                      <Menu.Item
                        key="/admin/settings"
                        icon={<SettingOutlined />}
                      >
                        <Link to={`${url}admin/settings`}>Settings</Link>
                      </Menu.Item>
                    </Menu.SubMenu>
                  )}
                </Menu>
              </div>
              <div>
                <Menu mode="inline" selectedKeys={[]}>
                  <Menu.Item
                    icon={<PieChartOutlined />}
                    onClick={() => setShowAnalytics(true)}
                  >
                    Analytics
                  </Menu.Item>
                </Menu>
                <Menu mode="inline" selectedKeys={[]}>
                  <Menu.Item
                    icon={<GlobalOutlined />}
                    onClick={() =>
                      window.open(
                        `https://support.${process.env.REACT_APP_URL}`,
                        "_blank"
                      )
                    }
                  >
                    Support
                  </Menu.Item>
                </Menu>
              </div>
            </div>
          </Sider>
          <Content className="base_wrapper">
            <Switch>
              <Route exact path={`${path}`} component={Batch} />
              <Route exact path={`${path}batchs`} component={BatchHisotry} />
              <Route exact path={`${path}history`} component={History} />
              {userDetails.role !== "Sub" && (
                <Route exact path={`${path}invoices`} component={Invoices} />
              )}
              <Route exact path={`${path}subusers`} component={SubUsers} />
              <Route exact path={`${path}statements`} component={Statements} />
              <Route
                exact
                path={`${path}preferences`}
                component={Preferences}
              />
              <Route
                exact
                path={`${path}admin/services`}
                component={AdminServices}
              />
              <Route
                exact
                path={`${path}admin/providers`}
                component={AdminProviders}
              />
              <Route
                exact
                path={`${path}admin/login-logs`}
                component={LoginLogs}
              />
              <Route exact path={`${path}admin/users`} component={AdminUsers} />
              {/* <Route
                exact
                path={`${path}admin/manual-orders`}
                component={ManualBatch}
              /> */}
              <Route
                exact
                path={`${path}admin/history`}
                component={AdminHistory}
                // component={OrderTable}
              />
              <Route
                exact
                path={`${path}admin/batch-history`}
                component={AdminBatchHistory}
              />
              <Route
                exact
                path={`${path}admin/invoices`}
                render={() => <Invoices admin />}
              />
              <Route
                exact
                component={AdminSettings}
                path={`${path}admin/settings`}
              />
            </Switch>
          </Content>
        </Layout>
      </Layout>

      <Modal
        centered
        width="1200px"
        footer={false}
        destroyOnClose
        title="Analytics"
        maskClosable={false}
        visible={showAnalytics}
        onCancel={() => setShowAnalytics(false)}
      >
        <Analytics />
      </Modal>
    </>
  );
};

function mapStateToProps(state: any) {
  const userDetails: UserDetails = state.userDetails;
  return {
    userDetails,
  };
}

export default connect(mapStateToProps, null)(Base);

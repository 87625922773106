import axios, { AxiosInstance } from "axios";

import store from "../state/store";

class HttpClient {
  private client: AxiosInstance;

  constructor(baseUrl: string) {
    this.client = axios.create({
      baseURL: baseUrl,
      validateStatus: null,
    });
  }

  public get(path: string, data?: any) {
    return this.client.get(path, {
      params: data,
      headers: { "session-key": store.getState().configs.sessionToken },
    });
  }

  public post(path: string, data?: any) {
    return this.client.post(path, data, {
      headers: { "session-key": store.getState().configs.sessionToken },
    });
  }

  public patch(path: string, data?: any) {
    return this.client.patch(path, data, {
      headers: { "session-key": store.getState().configs.sessionToken },
    });
  }

  public delete(path: string, data?: any) {
    return this.client.delete(path, {
      params: data,
      headers: { "session-key": store.getState().configs.sessionToken },
    });
  }
}

export default new HttpClient(
  process.env.NODE_ENV === "production"
    ? "https://api.backmarketusa.com"
    : "http://localhost:3001"
      // "https://api.imeilookup.com"
);

import { List, message, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";

import httpClient from "../../../common/httpClient";
import { disabledServiceSubject } from "./user.utils";

export interface ClearDiscountProps {
  username: string;
}

export interface DisabledService {
  id: number;
  service: { id: number; title: string };
}

const { Title, Link } = Typography;

const ClearDiscount: React.FunctionComponent<ClearDiscountProps> = ({
  username,
}) => {
  const [loading, setLoading] = useState(false);
  const [disabledServices, setDisabledServices] = useState<DisabledService[]>(
    []
  );

  useEffect(() => {
    const unsub = disabledServiceSubject.subscribe(async () => {
      await fetchDisabledServices();
    });

    return () => {
      unsub.unsubscribe();
    };
  }, []);

  async function fetchDisabledServices() {
    setLoading(true);
    try {
      const { data } = await httpClient.get(
        "/user/admin/list-disabled-services",
        {
          username,
        }
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setDisabledServices(data.disabled);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function clearDisabledService(id: number) {
    setLoading(true);
    try {
      const { data } = await httpClient.post(
        "/user/admin/clear-disabled-service",
        { id }
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("Disabled service cleard.");
      await fetchDisabledServices();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Clear Disabled Services <CloseCircleOutlined style={{ color: "red" }} />
      </Title>
      <List
        loading={loading}
        dataSource={disabledServices}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Link
                key="Clear-Discount"
                onClick={() => clearDisabledService(item.id)}
              >
                Clear
              </Link>,
            ]}
          >
            <List.Item.Meta title={item.service.title} />
          </List.Item>
        )}
      />
    </>
  );
};

export default ClearDiscount;

import React, { useEffect } from "react";
import { Router } from "react-router-dom";

import "./App.scss";
import Routes from "./Routes";
import history from "./history";

function App() {
  // const history = useHistory

  useEffect(() => {
    document
      .getElementById("theme-sheet")!
      .setAttribute(
        "href",
        `${process.env.PUBLIC_URL}/assets/styles/theme.${
          localStorage.getItem("theme") || "light"
        }.css`
      );

    if (history.location.pathname === "/register") {
      return;
    }

    history.replace(`/session?navigate=${history.location.pathname}`);
  }, []);

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
}

export default App;

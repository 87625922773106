import {
  Row,
  Col,
  Form,
  Button,
  message,
  Typography,
  InputNumber,
} from "antd";
import React, { useState } from "react";
import { BarsOutlined } from "@ant-design/icons";

import httpClient from "../../../common/httpClient";
import SelServices from "../../../common/Components/SelectService";
import { discoutnServiceSubject } from "./user.utils";

export interface DiscountServiceProps {
  username: string;
}

const { Title } = Typography;

const DiscountService: React.FunctionComponent<DiscountServiceProps> = ({
  username,
}) => {
  const [loading, setLoading] = useState(false);
  const [selServiceId, setSelServiceId] = useState<number>()

  async function handleSubmit(values: any) {
    values.username = username;
    values.serviceId = selServiceId;
    setLoading(true);
    try {
      const { data } = await httpClient.post(
        "/service/discount-service",
        values
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      discoutnServiceSubject.next(null);
      message.success("Service added to discount for user.");
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Add Discount Service <BarsOutlined />
      </Title>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
        <Row style={{ marginBottom: "-10px" }} gutter={[10, 10]}>
          <Col span={6}>
            <Form.Item
              name="newPrice"
              label="New Price"
              tooltip="New price for service."
            >
              <InputNumber min={0.001} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col span={18}>
            <Form.Item
              label="Service"
              name="serviceId"
              tooltip="Type to search service"
            >
              <SelServices size="middle" setSelService={setSelServiceId} />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button htmlType="submit" type="primary" loading={loading}>
            Add
          </Button>
        </div>
      </Form>
    </>
  );
};

export default DiscountService;

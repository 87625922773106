import { useStore } from "react-redux";
import React, { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { CreditCardOutlined, SettingOutlined } from "@ant-design/icons";
import { Button, Col, Form, message, Row, Select, Typography } from "antd";

import httpClient from "../../../common/httpClient";

export interface BulkActionsProps {}

const { Title } = Typography;

const BulkActions: React.FunctionComponent<BulkActionsProps> = () => {
  const store = useStore();
  const [loading, setLoading] = useState(false);
  const isMobile = useMediaQuery({ query: "(max-width: 600px)" });

  async function updateUsersPaymentMethod(values: {
    action: string;
    method: string;
  }) {
    if (!!!store.getState().configs.sessionToken) return;
    setLoading(true);
    try {
      const { data } = await httpClient.patch(
        "/payment/admin/update-users-paymentmethod",
        values
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success(`Updated ${data.total || 0} Users`);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  /**
   * @description fetch user price groups from remote api
   */
  // async function fetchPriceGroups() {
  //   if (!!!store.getState().configs.sessionToken) return;
  //   setLoading(true);
  //   try {
  //     const { data } = await httpClient.get("/user/price-group", {
  //       size: size,
  //       page: page - 1,
  //     });

  //     if (data.statusCode === 500 || data.error) {
  //       switch (typeof data.message) {
  //         case "object":
  //           data.message.forEach((err: string) => message.error(err));
  //           return;
  //         default:
  //           message.error(data.message);
  //           return;
  //       }
  //     }

  //     setTotal(data.total);

  //     const _priceGroups = data.groups.map((batch: any, index: number) => {
  //       batch["key"] = index;
  //       return batch;
  //     });

  //     setPriceGroups(_priceGroups);
  //   } catch (err) {
  //     message.error("Something went wrong.");
  //   } finally {
  //     setLoading(false);
  //   }
  // }

  return (
    <div style={{ marginTop: "30px" }}>
      <Title level={4}>
        Bulk User Actions <SettingOutlined />
      </Title>
      <Form
        layout="vertical"
        requiredMark={false}
        onFinish={updateUsersPaymentMethod}
      >
        <div
          style={{
            overflowY: "auto",
            minHeight: "calc(100vh - 227px)",
            maxHeight: "calc(100vh - 227px)",
          }}
        >
          <Title level={5}>
            Bulk Payment Actions <CreditCardOutlined />
          </Title>
          <Row>
            <Col span={isMobile ? 24 : 12}>
              <Row gutter={[10, 10]} align="bottom">
                <Col span={12}>
                  <Form.Item
                    name="action"
                    label="Action"
                    rules={[{ required: true, message: "Action is required." }]}
                  >
                    <Select
                      disabled={loading}
                      allowClear
                      placeholder="Select Action"
                    >
                      <Select.Option value="Add">Add</Select.Option>
                      <Select.Option value="Remove">Remove</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="method"
                    label="Payment method"
                    rules={[
                      {
                        required: true,
                        message: "Payment method required.",
                      },
                    ]}
                  >
                    <Select
                      disabled={loading}
                      allowClear
                      placeholder="Select Payment Method."
                    >
                      <Select.Option value="Paypal">Paypal</Select.Option>
                      <Select.Option value="Stripe">Stripe</Select.Option>
                      <Select.Option value="Coinbase">Coinbase</Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {/* <Col span={6}>
                </Col> */}
                  <Button
                    // size="large"
                    type="primary"
                    loading={loading}
                    htmlType="submit"
                    style={{ width: "100%" }}
                  >
                    Update
                  </Button>
              </Row>
            </Col>
          </Row>
        </div>
      </Form>
    </div>
  );
};

export default BulkActions;

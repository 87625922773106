import { List, message, Typography } from "antd";
import React, { useEffect, useState } from "react";
import { PercentageOutlined } from "@ant-design/icons";

import httpClient from "../../../common/httpClient";
import { discoutnServiceSubject } from "./user.utils";

export interface ClearDiscountProps {
  username: string;
}

const { Title, Link } = Typography;

const ClearDiscount: React.FunctionComponent<ClearDiscountProps> = ({
  username,
}) => {
  const [loading, setLoading] = useState(false);
  const [disServices, setDisServices] = useState<any[]>([]);

  useEffect(() => {
    const unsub = discoutnServiceSubject.subscribe(async () => {
      await fetchService();
    });

    return () => {
      unsub.unsubscribe();
    };
  }, []);

  async function fetchService() {
    setLoading(true);
    try {
      const { data } = await httpClient.get("/service/discount-user-services", {
        username,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      setDisServices(data.services);
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  async function clearDiscount(id: number) {
    setLoading(true);
    try {
      const { data } = await httpClient.delete("/service/discount-service", {
        username,
        serviceId: id,
      });

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      message.success("Discount cleard.");
      await fetchService();
    } catch (err) {
      message.error("Something went wrong.");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Clear Discount Services <PercentageOutlined />
      </Title>
      <List
        loading={loading}
        dataSource={disServices}
        renderItem={(item: any) => (
          <List.Item
            actions={[
              <Link
                key="Clear-Discount"
                onClick={() => clearDiscount(item.service.id)}
              >
                Clear
              </Link>,
            ]}
          >
            <List.Item.Meta title={item.service.title} />
          </List.Item>
        )}
      />
    </>
  );
};

export default ClearDiscount;

import React, { useState } from "react";
import { CloseCircleOutlined } from "@ant-design/icons";
import { Row, Col, Form, Button, message, Typography } from "antd";

import httpClient from "../../../common/httpClient";
import { disabledServiceSubject } from "./user.utils";
import SelServices from "../../../common/Components/SelectService";

export interface DiscountServiceProps {
  username: string;
}

const { Title } = Typography;

const DiscountService: React.FunctionComponent<DiscountServiceProps> = ({
  username,
}) => {
  const [loading, setLoading] = useState(false);
  const [selServiceId, setSelServiceId] = useState<number>();

  async function handleSubmit(values: any) {
    values.username = username;
    values.service = selServiceId;
    setLoading(true);
    try {
      const { data } = await httpClient.post(
        "/user/admin/disabled-service",
        values
      );

      if (data.statusCode === 500 || data.error) {
        switch (typeof data.message) {
          case "object":
            data.message.forEach((err: string) => message.error(err));
            return;
          default:
            message.error(data.message);
            return;
        }
      }

      disabledServiceSubject.next(null);
      message.success("Service disabled for user.");
    } catch (err) {
      message.error("Something went wrong");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Title level={4}>
        Disable Service <CloseCircleOutlined style={{ color: "red" }} />
      </Title>
      <Form layout="vertical" onFinish={handleSubmit} requiredMark={false}>
        <Row style={{ marginBottom: "-10px" }} gutter={[10, 10]}>
          <Col span={24}>
            <Form.Item
              label="Service"
              name="service"
              tooltip="Type to search service"
            >
              <SelServices size="middle" setSelService={setSelServiceId} />
            </Form.Item>
          </Col>
        </Row>
        <div style={{ textAlign: "right" }}>
          <Button htmlType="submit" type="primary" loading={loading}>
            Disable
          </Button>
        </div>
      </Form>
    </>
  );
};

export default DiscountService;
